<template>
  <div id="naverIdLogin"></div>
</template>

<script>
  export default {
    name: "Naver",
    mounted() {
      console.log(decodeURIComponent(window.location.href.split(/[#&=]+/)[2]))
    }
  }
</script>

<style scoped lang="scss">

</style>